@font-face {
    font-family: 'blog-icon';  /* project id 760845 */
    src: url('//at.alicdn.com/t/font_760845_wqx1lxjeq.eot');
    src: url('//at.alicdn.com/t/font_760845_wqx1lxjeq.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_760845_wqx1lxjeq.woff') format('woff'),
    url('//at.alicdn.com/t/font_760845_wqx1lxjeq.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_760845_wqx1lxjeq.svg#blog-icon') format('svg');
}

.icon-font{
    font-family: blog-icon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
}
