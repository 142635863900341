/* You can add global styles to this file, and also import other style files */
@import "assets/iconfont";

*{
    font-family:"Trebuchet MS",Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
}
body,html{
    height: 100%;
}
body{
    min-width: 240px;
    //position: relative;
}

.ease {
    -webkit-transition: All .2s ease;
    -moz-transition: All .2s ease;
    -o-transition: All .2s ease;
    /*-ms-transition: All 2s ease;*/
    transition: All .2s ease;
}

a{
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
    &:visited{
        color: #FFF;
        text-decoration: none;
    }
    &:hover{
        text-decoration: none;
    }
}

.frame-mask{
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 33;
    background-color: rgba(0,0,0,.65);

}

footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    color: #FFF;
    text-align: center;
    z-index: 999;
    bottom: 0;
    p{
        font-size: 14px;
        margin: 0;
    }
}


.loading{
    width: 100%;
}

.spinner {
    margin: 20px auto;
    width: 50px;
    height: 60px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
    background-color: #00a2d4;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 2px;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}
.cirtle-loading{
    width: 100%;
    height: 200px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
}

.loadEffect{
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 auto;
    margin-top:100px;
}
.loadEffect span{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #38b7ea;
    position: absolute;
    -webkit-animation: load 1.04s ease infinite;
}
@-webkit-keyframes load{
    0%{
        -webkit-transform: scale(1.2);
        opacity: 1;
    }
    100%{
        -webkit-transform: scale(.3);
        opacity: 0.5;
    }
}
.loadEffect span:nth-child(1){
    left: 0;
    top: 50%;
    margin-top:-10px;
    -webkit-animation-delay:0.13s;
}
.loadEffect span:nth-child(2){
    left: 14px;
    top: 14px;
    -webkit-animation-delay:0.26s;
}
.loadEffect span:nth-child(3){
    left: 50%;
    top: 0;
    margin-left: -10px;
    -webkit-animation-delay:0.39s;
}
.loadEffect span:nth-child(4){
    top: 14px;
    right:14px;
    -webkit-animation-delay:0.52s;
}
.loadEffect span:nth-child(5){
    right: 0;
    top: 50%;
    margin-top:-10px;
    -webkit-animation-delay:0.65s;
}
.loadEffect span:nth-child(6){
    right: 14px;
    bottom:14px;
    -webkit-animation-delay:0.78s;
}
.loadEffect span:nth-child(7){
    bottom: 0;
    left: 50%;
    margin-left: -10px;
    -webkit-animation-delay:0.91s;
}
.loadEffect span:nth-child(8){
    bottom: 14px;
    left: 14px;
    -webkit-animation-delay:1.04s;
}

router-outlet ~ * {
    position: absolute;
    height: 100%;
    width: 100%;
}
